<template>
  <detail :items="items"></detail>
</template>

<script>
import Detail from "../components/Detail.vue";
export default {
  components: { Detail },
  data() {
    return {
      items: [
        {
          label: "经典案例",
          path: "/production",
        },
        {
          label: "案例介绍",
        },
      ],
    };
  },
};
</script>

<style>
</style>